//~ DEPENDENCIES
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
//~ COMPONENTS
import { Flex, Text } from '@chakra-ui/react';
import Layout from '../components/layout'
import Seo from '../components/Seo/index'
import Links from '../components/Link';

//~ MEDIA
import notFound from '../assets/images/not-found.svg'

//~ MISCELLANEOUS

const Template = () => {
  const data = useStaticQuery(graphql`
	query
	{
		sanityHeader
		{
			topBarVisibility
			topbarMessage
			{
				Messages
				{
					_rawContent(resolveReferences:{maxDepth: 1000}
				)
				}
			}
			logo
			{
				alt
				asset
				{
					gatsbyImageData
				}
			}
			ctaButton
			{
				button
				{
					textLink
					text
					buttonTextColor
					{
						value
					}
					buttonColor
					{
						value
					}
				}
			}
			menuItemsNav
			{
				headers
				{
					headerText
					columns
					{
						... on SanityArticleColumn {
								image {
									asset {
										gatsbyImageData
									}
								},
								slug {
									current
								},
								description
						}
						... on SanityNavColumn {
							columnText
							columnItems
							{
							...SanitySimpleLink
							...SanityNavigationLink
							}
						}
					}
				}
			}
		}
		
		sanityFooter
		{
			logo
			{
				alt
				asset
				{
					gatsbyImageData
				}
			}
			socialLinks
			{
				facebookUrl
				linkedinUrl
				instagramUrl
			}
			contactUsSection
			{
				phoneContactNumber
				phoneContactNumberLink
				{
					current
				}
				emailAddressText
				eventLink
				{
					text
					link
					{
						current
					}
				}
				emailContactAddressLink
				{
					current
				}
			}
			newsletterSection
			{
				newsletterTitle
				newsletterDescription
			}
			creditsLinks
			{
				text
				link
				{
					current
				}
			}
		}
	}
`)
	
  return (
	<Layout header={data?.sanityHeader} footer={data?.sanityFooter}>
		<Seo/>
		<Flex flexDirection='column' align='center' mb={5}>
			<img src={notFound} alt='404 not found'/>
			<Text as='h3' my={3} textAlign='center' color='secondary'>Oops!! Something went wrong!</Text>
			<Text as='p' mb={3} textAlign='center'>The page you were looking for does not exist</Text>
			<Links to='/' mb={3} type='button'><Text color='white'>Back to home</Text></Links>
		</Flex>
	</Layout>
  )
}

export default Template